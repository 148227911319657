import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import leadsSlice from "../features/leads/leadSlice";
import productSlice from "../features/common/product/productSlice";
import multipleChoiceSlice from "../features/common/multipleChoice/multipleChoiceSlice";
import essaySlice from "../features/common/essay/essaySlice";
import assessmentSlice from "../features/common/assessment/assessmentSlice";

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  product: productSlice,
  multipleChoice: multipleChoiceSlice,
  essay: essaySlice,
  assessment: assessmentSlice,
};

const store = configureStore({
  reducer: combinedReducer,
});

store.subscribe(() => {
  console.log("Store changes", store.getState());
});

export default store;
