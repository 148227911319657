import { createContext, useContext, useState, useEffect } from "react";
import { PropTypes } from "prop-types";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() =>
    JSON.parse(localStorage.getItem("user")),
  );
  const [token, setToken] = useState(() => localStorage.getItem("token"));

  // No need for useEffect to set the state on mount, as we initialize state from localStorage
  // directly in useState using lazy initialization.

  const storeLoginData = (data) => {
    const userType = data.superAdmin ? "superAdmin" : "admin";
    const { id, fullName, email, phoneNumber, image } = data[userType];
    const token = data.accessToken;
    const refreshToken = data.refreshToken;

    const userData = {
      id,
      fullName,
      email,
      phoneNumber,
      image,
      userType,
    };

    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("accessToken", token); // Ensure token is stored correctly
    localStorage.setItem("refreshToken", refreshToken);

    setUser(userData);
    setToken(token);

    setTimeout(() => {
      const redirectPath =
        userType === "superAdmin" ? "/app/dashboard" : "/app/admin/dashboard";
      window.location.replace(redirectPath);
    }, 1000);
  };

  const updateUserData = (updatedData) => {
    const updatedUser = {
      ...user,
      ...updatedData,
    };

    localStorage.setItem("user", JSON.stringify(updatedUser));
    setUser(updatedUser);
  };

  const logout = () => {
    localStorage.clear();
    setUser(null);
    setToken(null);
    window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{ user, token, storeLoginData, updateUserData, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
